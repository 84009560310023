import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";

const ImageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

const ErrorContainer = styled("div")({
  maxWidth: "640px",
  height: "100vh",
});

const ErrorContentContainer = styled(Stack)({
  paddingTop: "33%",
  paddingLeft: "16px",
  paddingRight: "16px",
});

const ErrorTitleText = styled(Typography)({
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "36px",
  color: "#1A1A1A",
  textAlign: "center",
});

const ErrorContentText = styled(Typography)({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#515151",
  textAlign: "center",
});

const MaintenancePage = () => {
  return (
    <ErrorContainer id="maintenance-page">
      <ErrorContentContainer
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <ErrorTitleText>Mohon tunggu sebentar</ErrorTitleText>
        <ErrorContentText>
          <span style={{ margin: "0px", display: 'block' }}>
            Sedang ada proses optimalisasi transaksi
          </span>
          <span style={{ margin: "0px", display: 'block' }}>dengan partner ini.</span>
          <span style={{ margin: "0px", display: 'block' }}>Silakan coba beberapa saat lagi.</span>
        </ErrorContentText>
        <img
          style={{ width: "375px", height: "375px" }}
          src={ImageBaseUrl + "/maintenanceNew.png"}
          alt="maintenance"
        />
      </ErrorContentContainer>
    </ErrorContainer>
  );
};

export default MaintenancePage;
